const baudrates = document.getElementById("baudrates") as HTMLSelectElement;
const consoleBaudrates = document.getElementById("consoleBaudrates") as HTMLSelectElement;
const connectButton = document.getElementById("connectButton") as HTMLButtonElement;
const traceButton = document.getElementById("copyTraceButton") as HTMLButtonElement;
const disconnectButton = document.getElementById("disconnectButton") as HTMLButtonElement;
const resetButton = document.getElementById("resetButton") as HTMLButtonElement;
const consoleStartButton = document.getElementById("consoleStartButton") as HTMLButtonElement;
const consoleStopButton = document.getElementById("consoleStopButton") as HTMLButtonElement;
const eraseButton = document.getElementById("eraseButton") as HTMLButtonElement;
const addFileButton = document.getElementById("addFile") as HTMLButtonElement;
const programButton = document.getElementById("programButton");
const filesDiv = document.getElementById("files");
const programDiv = document.getElementById("program");
const consoleDiv = document.getElementById("console");
const lblBaudrate = document.getElementById("lblBaudrate");
const lblConsoleBaudrate = document.getElementById("lblConsoleBaudrate");
const lblConsoleFor = document.getElementById("lblConsoleFor");
const lblConnTo = document.getElementById("lblConnTo");
const table = document.getElementById("fileTable") as HTMLTableElement;
const alertDiv = document.getElementById("alertDiv");
const restart = document.getElementById("Restart");
const serialNumber = document.getElementById("serialNumber"); 
const versionNumber = document.getElementById("versionNumber");
// This is a frontend example of Esptool-JS using local bundle file
// To optimize use a CDN hosted version like
// https://unpkg.com/esptool-js@0.2.0/bundle.js
import { ESPLoader, FlashOptions, LoaderOptions, Transport } from "../../../lib";
import { serial } from "web-serial-polyfill";
if (!navigator.serial && navigator.usb) navigator.serial = serial;

declare let CryptoJS; // CryptoJS is imported in HTML script


let device = null;
let transport: Transport;
let chip: string = null;
let esploader: ESPLoader;
let isConsoleClosed = false;

connectButton.onclick = async () => {
  if (device === null) {
    // filter usb serial devices by vendor id 0x303a
    const filters = [{ usbVendorId: 0x303a }];
    device = await navigator.serial.requestPort({ filters });
    await navigator.serial.getPorts();
    transport = new Transport(device, true);
  }
  if(connectButton.value == "Connect"){
 
    try {
      connectButton.disabled = true;
      connectButton.value = "Connecting...";
      await transport.connect(115200);
      console.log("Connected with baud 115200")
      await transport.rawRead(1000);
        const encoder = new TextEncoder();

        await transport.write(encoder.encode("GET_SERIAL\n"));
        console.log("Serial request sent")

        const serialResponse = await transport.rawRead(1000);
        serialNumber.innerHTML = new TextDecoder().decode(serialResponse);

        await transport.write(encoder.encode("GET_VERSION\n"));
        console.log("Version request sent")

        const versionResponse = await transport.rawRead(1000)
        versionNumber.innerHTML  = new TextDecoder().decode(versionResponse);

        await transport.disconnect()
      
      
      const flashOptions = {
        transport,
        baudrate: 921600,
        terminal: null,
      } as LoaderOptions;
      esploader = new ESPLoader(flashOptions);

      chip = await esploader.main();

      connectButton.disabled = false;
      connectButton.value = "Connected";
      restart.style.display = "block";
      // Temporarily broken
      // await esploader.flashId();
      programButton.style.display = "initial";
    
    } catch (e) {

      console.error(e);
      const alertMsg = document.getElementById("alertmsg");

      alertMsg.innerHTML = "<strong>" + e.message + "</strong>";
      alertDiv.style.display = "block";
      connectButton.disabled = false;
      programButton.style.display = "none";
      connectButton.value = "Connect";
      restart.style.display = "none";
      device = null;
    
      
    }
  }else{
    await esploader.hardReset()
    await transport.disconnect()
    transport = null;
    device = null;
    serialNumber.innerHTML = ""
    versionNumber.innerHTML = ""
    connectButton.value = "Connect";
    programButton.style.display = "none";
    restart.style.display = "none";
  }

};


programButton.onclick = async () => {
  const statusDiv = document.getElementById("statusDiv");
  statusDiv.style.display = "block";
  const alertMsg = document.getElementById("alertmsg");

  // Hide error message
  alertDiv.style.display = "none";

  const fileArray = [];
  const progressBars = [];

  // download loomee.bin file as blob with ajax
  const response = await fetch('loomee.bin');

  if(response.status !== 200) {
    alertMsg.innerHTML = "<strong>Failed to download loomee.bin file</strong>";
    alertDiv.style.display = "block";
    return;
  }
  const fileData = await response.arrayBuffer();
  const fileObj = { data: esploader.ui8ToBstr(new Uint8Array(fileData)) };
  
  const progressBar = document.getElementById("progressBar");

  progressBar.textContent = "0";
  progressBars.push(progressBar);


  fileArray.push({ data: fileObj.data, address: 0x10000 });
  

  try {
    const flashOptions: FlashOptions = {
      fileArray: fileArray,
      flashSize: "keep",
      eraseAll: false,
      compress: true,
      reportProgress: (fileIndex, written, total) => {
        progressBars[fileIndex].value = (written / total) * 100;
      },
      calculateMD5Hash: (image) => CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
    } as FlashOptions;
    await esploader.writeFlash(flashOptions);
    await esploader.hardReset();
    connectButton.disabled = false;
    connectButton.value = "Connect";
    connectButton.style.display = "none";
    programButton.style.display = "none";
    alertMsg.innerHTML = "<strong>Programmed successfully</strong>";
    restart.style.display = "none";
    alertDiv.style.display = "block";
    alertDiv.classList.remove("alert-danger");
    alertDiv.classList.add("alert-success");
    transport.disconnect();
    transport = null;
    device = null;

  } catch (e) {
    console.error(e);
    
    alertMsg.innerHTML = "<strong>" + e.message + "</strong>";
    alertDiv.style.display = "block";
    connectButton.disabled = false;
    connectButton.value = "Connect";
    programButton.style.display = "none";
    transport.disconnect();
    device = null;
  } finally {
    // Hide progress bars and show erase buttons
    statusDiv.style.display = "none";
  }
};
restart.onclick = () => {
  esploader.hardReset().then(succces=>{

    programButton.style.display = "none";
    connectButton.value = "Connect"
    restart.style.display = "none";
    transport.disconnect();
    device = null;
    transport = null;

  })
}